<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="800"
    persistent
    scrollable
  >
    <v-card>
      <v-card-title>{{ $t('general.my_preferences') }}</v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <v-checkbox
              v-model="assignee.message_on_issue"
              :label="$t('projects.labels.receive_issue_messages')"
              class="mt-0"
            />
          </v-col>

          <v-col v-if="assignee.message_on_issue" cols="12" sm="6">
            <LanguageSelector
              :selected-locale="assignee.language"
              :label="$t('projects.labels.message_language')"
              styles="margin-left: -8px !important;"
              @change="assignee.language = $event"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text @click="goBack">
          {{ $t('general.controls.cancel') }}
        </v-btn>
        <v-btn :disabled="saving" :loading="saving" color="primary" text @click="onSave">
          {{ $t('general.controls.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import LanguageSelector from '@/components/LanguageSelector';
import { mapActions, mapGetters, mapState } from 'vuex';
import clone from 'just-clone';

export default {
  name: 'ProjectSettings',

  components: { LanguageSelector },

  data() {
    return {
      assignee: {},
    };
  },

  computed: {
    ...mapState('auth', ['currentUser']),
    ...mapState('projects', ['selectedProject']),
    ...mapGetters(['loading']),

    currentUserAssignee() {
      return this.selectedProject.assignees.find((a) => a.user_id === this.currentUser.id);
    },

    saving() {
      return this.loading[
        `put:api/projects/assignees/${this.currentUserAssignee?.id}/user-settings`
      ];
    },
  },

  created() {
    if (!this.currentUserAssignee) {
      this.$router.replace({ name: 'project' });
      return;
    }

    this.assignee = clone(this.currentUserAssignee);
  },

  methods: {
    ...mapActions('projects', ['updateProjectAssigneeSettings']),

    goBack() {
      this.$router.push({ name: 'project' });
    },

    onSave() {
      this.updateProjectAssigneeSettings(this.assignee).then(() => {
        this.goBack();
      });
    },
  },
};
</script>

<style scoped></style>
